import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import post1 from "../assets/Blogs and articles/post1.png";
import article_by_sandeep_sir from "../assets/Blogs and articles/article_by_sandeep_sir.png";
import g20 from "../assets/Blogs and articles/G20.png";
import toi_article from "../assets/Blogs and articles/toi_article.png";
import Footer from "../pages/Footer";
import Navbar from "../homeComponents/Navbar";
import SmallCards from "./SmallCards";

import baseURL from "../baseURL";
import { Link, useNavigate } from "react-router-dom";

const Blog = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });

  const navigate = useNavigate();

  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.down("md"));
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [sliceLastTwoData, setSliceLastTwoData] = useState([]);
  const [sliceFirstToSecondLast, setSliceFirstToSecondLast] = useState([]);
  const [sliceFirstFourToLast, setSliceFirstToLast] = useState([]);
  const [sliccRemainData, setRemainData] = useState([]);

  useEffect(() => {
    async function getData() {
      const response = await fetch(`${baseURL}/resources/blog/`);
      const responsJson = await response.json();

      let newArr = [];
      newArr[0] = responsJson[responsJson.length - 1];
      newArr[1] = responsJson[responsJson.length - 2];

      setSliceLastTwoData(newArr);

      // for top three and four news
      setSliceFirstToSecondLast(responsJson.slice(2, 4));

      //for news from top 4 to last....
      // sliceFirstFourToLast(responsJson.slice(0,responsJson.length));
      setRemainData(responsJson.slice(0, responsJson.length - 2));
      console.log(sliceFirstFourToLast);
    }
    getData();
  }, []);

  console.log("remainData", sliccRemainData);
  console.log("slice two", sliceFirstToSecondLast);

  function click(blogurl, id) {
    console.log("click", id);
    if (id === 10) {
      navigate(`/blog-page/${id}`,{target: "_blank"});
    }
  }

  const handleClick = (e) => {
    e.preventDefault();
    window.open('/blog-page/10', '_blank');
  };

  return (
    <div>
      <Navbar />

      <Grid
        container
        // spacing={2}
        style={
          {
            // border: "blue 10px solid",
          }
        }
        sx={{
          padding: "3% 2%",
          gap: isSmall || isMedium ? 2 : 0,
          // border: "2px solid red",
        }}
      >
        {sliceLastTwoData.length > 0 &&
          sliceLastTwoData.map((itemss) => {
            return (
              <Grid
                item
                xs={12}
                md={4}
                style={
                  {
                    // border: "yellow 10px solid",
                  }
                }
              >
                {/* big card */}
                <Paper
                  onClick={() => click(itemss.resource_url,itemss.id)}
                  sx={{
                    width: "97%",
                    height: "98%",
                    borderRadius: "20px",
                    // border: "4px solid red",
                    // border: "2px solid blue ",
                    position: "relative",
                    overflow: "hidden",
                    // padding:"2%",
                    transition: "transform 0.3s ease-in-out",
                    ":hover": {
                      transform: "scale(1.05)",
                    },
                  }}
                >
                  {sliceLastTwoData.length > 0 && (
                    <div style={{ height: "100%" , 
                    // border:"6px solid blue"
                    }}>
                      <a
                        href={itemss.resource_url}
                        target= {itemss.resource_url&& "_blank"}
                        alt="image_blog"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={itemss.image}
                          alt="Card 1"
                          style={{ width: "100%", height: "80%" }}
                        />
                      </a>
                      <Typography
                        variant="h6"
                        style={{
                          position: "absolute",
                          bottom: 0,
                          left: 0,
                          right: 0,
                          backgroundColor: "#64748b",
                          color: "#d6ff41",
                          padding: "8px",
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "medium",
                          height:"90px"
                        }}
                      >
                        {itemss.tittle}
                        <br />
                        {itemss.resourse_text}
                      </Typography>
                    </div>
                  )}
                </Paper>
                {/* big card end */}
              </Grid>
            );
          })}

        {sliccRemainData.length > 0 &&
          sliccRemainData.map((item22) => {
            return (
              <Grid
                item
                xs={12}
                md={4}
                style={
                  {
                    // border: "yellow 2px solid",
                  }
                }
              >
                {/* big card */}
                <Paper
                onClick={() => click(item22.resource_url,item22.id)}
                  sx={{
                    width: "97%",
                    height: "98%",
                    borderRadius: "20px",
                    // border: "4px solid red",
                    // border: "2px solid blue ",
                    position: "relative",
                    overflow: "hidden",
                    // padding:"2%",
                    // marginLeft:"10px",
                    transition: "transform 0.3s ease-in-out",
                    ":hover": {
                      transform: "scale(1.05)",
                    },
                  }}
                >
                  {sliceLastTwoData.length > 0 && (
                    <div style={{ height: "100%",
                    //  border: "2px solid red"
                      }}>
                      <a
                        href={item22.resource_url}
                        target=  "_blank"
                        alt="image_blog"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={item22.image}
                          alt="Card 1"
                          style={{ width: "100%", height: "80%" }}
                        />
                      </a>
                      <Typography
                        variant="h6"
                        style={{
                          position: "absolute",
                          bottom: 0,
                          left: 0,
                          right: 0,
                          backgroundColor: "#64748b",
                          color: "#d6ff41",
                          padding: "8px",
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "medium",
                          height:"90px"
                        }}
                      >
                        {item22.tittle}
                        <br />
                        {item22.resourse_text}
                      </Typography>
                    </div>
                  )}
                </Paper>
                {/* big card end */}
              </Grid>
            );
          })}
      </Grid>

      <Footer />
    </div>
  );
};

export default Blog;
