import React, { useEffect, useState } from "react";
import ndvidiaLogo from "../assets/nvidia-inception-program-badge-rgb-for-screen.svg";
import skewbLogo from "../assets/SkewbBlog.png";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import baseURL from "../baseURL";
import { useParams } from "react-router-dom";

function BlogPage() {
  const { id } = useParams();
  const [blogPage, setBlogPage] = useState([]);
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const isMatchSmall = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    async function getData() {
      const response = await fetch(`${baseURL}/resources/blog/`);
      const responsJson = await response.json();
      console.log("responseData", responsJson);

      const filterData = responsJson.filter((ele) => {
        return ele.id === id;
      });
      console.log("filterData", filterData);
      setBlogPage(filterData);
    }

    getData();
  }, []);

  console.log("blogData", blogPage);
  let heading = "Skewb Analytics Joins NVIDIA Inception";

  return (
    <Box sx={{ backgroundColor: "black" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // marginBottom: "2rem", // Add margin bottom to create spacing
        }}
      >
        <Box marginRight="1.8rem" marginLeft={"0.8rem"} >
          {" "}
          {/* Add margin right to the first image box */}
          <img
            src={skewbLogo}
            height={isMatch || isMatchSmall ? "60px" : "65px"}
            width={isMatch || isMatchSmall ? "130px" : "180px"}
            // height:
            alt="Skewb Logo"
          />
        </Box>
        <Box>
          <img
            src={ndvidiaLogo}
            width={(isMatch || isMatchSmall) && "162px"}
            height={isMatch || isMatchSmall ? "75px" : "84px"}
            alt="NVIDIA Logo"
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h2
          style={{
            color: "#d6ff41",
            fontFamily: "Inter",
            fontWeight: "300",
            fontSize: "2rem",
            lineHeight: "1.2",
            textAlign: (isMatch || isMatchSmall) && "center",
          }}
        >
          {heading}
        </h2>
        <Box
          sx={{
            // border: "2px solid red",
            padding: isMatch || isMatchSmall ? "0 9% 0 9% " : "0 17% 0 17%",

            fontFamily: "inter",
            fontWeight: "400",
            color: "white",
            //  textAlign:"center",
            fontSize: "19px",
            lineHeight: "161%",
          }}
        >
          <Typography variant="p">
            <b> NEW DELHI—March 31, 2024—</b>Skewb Analytics today announced it
            has joined{" "}
            <a
              style={{ color: "#d6ff41" }}
              target="_blank"
              href="https://www.nvidia.com/en-us/startups/?ncid=partn-27551#cid=in01_partn_en-us"
            >
              {" "}
              NVIDIA Inception{" "}
            </a>
            , a program that nurtures startups revolutionizing industries with
            technological advancements.
            <br />
            Skewb Analytics is revolutionizing data-driven decision-making
            through its artificial intelligence (AI) and machine
            learning-powered platform, via seamless integration with existing
            platforms, user-friendly interfaces and real-time analytics. Skewb
            Analytics provides confident client strategy with a 95%+ accurate
            forecast.
            <br />
            Skewb Analytics plans to use the resources available through NVIDIA
            Inception, including access to technical support provided by NVIDIA
            experts, to optimize its AI models, algorithms and overall
            performance, through relevant technology architecture and parallel
            processing. Skewb Analytics also sees an opportunity to collaborate
            with industry-leading experts and other AI-driven organizations.
            <br />
            Sandeep Pandey, Skewb Analytics CEO and Co-Founder, said,
            <i>
              {" "}
              “The NVIDIA Inception program will enhance our credibility in the
              AI space and provide valuable exposure to potential customers,
              investors and collaborators.”
            </i>
            <br />
            NVIDIA Inception helps startups during critical stages of product
            development, prototyping and deployment. Every NVIDIA Inception
            member gets a custom set of ongoing benefits, such as{" "}
            <a
              style={{ color: "#d6ff41" }}
              target="_blank"
              href="https://www.nvidia.com/en-us/training/?ncid=em-ded-n1-96486"
            >
              {" "}
              NVIDIA Deep Learning Institute{" "}
            </a>{" "}
            credits, preferred pricing on NVIDIA hardware and software, and
            technology assistance, which provides startups with the fundamental
            tools to help them grow.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default BlogPage;
