import React from "react";
import {
  Typography,
  Paper,
  useTheme,
  useMediaQuery,
  Box,
  Stack,
} from "@mui/material";

const Top = ({ image, title, data }) => {

  // console.log("data: ", data);
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const isMatchSmall = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <Paper
        elevation={3}
        sx={{
          backgroundColor: "rgba(0, 0, 0, 0.1)",
          color: "white",
          background: `url('${image}') center/cover`, // Use the provided image prop
          backgroundSize: isMatch || isMatchSmall ? "100%" : "75%",
          backgroundRepeat: "no-repeat",
          // border: "2px solid yellow",
          width: "80%",
          aspectRatio: 3 / 1,
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "center",
          margin: "auto",
        }}
      >
        {/* Heading 1 */}

        <Typography variant="h3" sx={{ textAlign: "center" }}>
          {title}
        </Typography>
      </Paper>
      <Box
        sx={{
          width: "80%",
          margin: "auto",
          paddingTop: "4%",
          // border: "1px solid red",
        }}
      >
        {/* subtitle */}
        <Stack
          sx={{ padding: "1rem 0", borderLeft: "5px solid green" }}
          gap={2}
        >
          {data?.subtitle.map((para, index) => {
            return (
              <Typography
                key={index}
                sx={{
                  color: "#8c99ab",
                  textAlign: "left",
                  marginLeft: "1rem",
                }}
              >
                {para}
              </Typography>
            );
          })}
        </Stack>
        {/* subtitle end */}

        {/* list1 */}
        {data?.list1 && (
          <Box sx={{ padding: "1rem 0" }}>
            {data?.list1?.title ? (
              <Typography variant="h5" sx={{ color: "white" }}>
                {data?.list1?.title}
              </Typography>
            ) : (
              <></>
            )}
            {data?.list1?.para ? (
              data?.list1?.para.map((item) => {
                return (
                  <Typography sx={{ color: "#8c99ab", padding: "1rem 0" }}>
                    {item}
                  </Typography>
                );
              })
            ) : (
              <></>
            )}

            {/* map list item and heading */}

            {data?.list1?.lists?.map((item) => (
              <Box sx={{ padding: "1rem 0", marginLeft: "2rem" }}>
                {item && (
                  <Typography sx={{ color: "#8c99ab" }}>
                    {item.heading}
                  </Typography>
                )}
                {item?.content?.map((li, index) => (
                  <li
                    key={index}
                    style={{ color: "#8c99ab", padding: "0.25rem 0" }}
                  >
                    {li}
                  </li>
                ))}
              </Box>
            ))}
            {/* map list item and heaind end */}
          </Box>
        )}
        {/* list1 end */}

        {/* list2 */}
        {data?.list2 && (
          <Box sx={{ padding: "1rem 0" }}>
            {data?.list2?.title ? (
              <Typography variant="h5" sx={{ color: "white" }}>
                {data?.list2?.title}
              </Typography>
            ) : (
              <></>
            )}
            {data?.list2?.para ? (
              data?.list2?.para.map((item) => {
                return (
                  <Typography sx={{ color: "#8c99ab", padding: "1rem 0" }}>
                    {item}
                  </Typography>
                );
              })
            ) : (
              <></>
            )}

            {/* map list item and heading */}

            {data?.list2?.lists?.map((item) => (
              <Box sx={{ padding: "1rem 0", marginLeft: "2rem" }}>
                {item && (
                  <Typography sx={{ color: "#8c99ab" }}>
                    {item.heading}
                  </Typography>
                )}
                {item?.content?.map((li, index) => (
                  <li
                    key={index}
                    style={{ color: "#8c99ab", padding: "0.25rem 0" }}
                  >
                    {li}
                  </li>
                ))}
              </Box>
            ))}
            {/* map list item and heaind end */}
          </Box>
        )}
        {/* list2 end */}

        {/* list3 */}
        {data?.list3 && (
          <Box sx={{ padding: "1rem 0" }}>
            {data?.list3?.title ? (
              <Typography variant="h5" sx={{ color: "white" }}>
                {data?.list3?.title}
              </Typography>
            ) : (
              <></>
            )}
            {data?.list3?.para ? (
              data?.list3?.para.map((item) => {
                return (
                  <Typography sx={{ color: "#8c99ab", padding: "1rem 0" }}>
                    {item}
                  </Typography>
                );
              })
            ) : (
              <></>
            )}

            {/* map list item and heading */}

            {data?.list3?.lists?.map((item) => (
              <Box sx={{ padding: "1rem 0", marginLeft: "2rem" }}>
                {item && (
                  <Typography sx={{ color: "#8c99ab" }}>
                    {item.heading}
                  </Typography>
                )}
                {item?.content?.map((li, index) => (
                  <li
                    key={index}
                    style={{ color: "#8c99ab", padding: "0.25rem 0" }}
                  >
                    {li}
                  </li>
                ))}
              </Box>
            ))}
            {/* map list item and heaind end */}
          </Box>
        )}
        {/* list3 end */}

        {/* body1 */}
        <Box sx={{ padding: "1rem 0" }}>
          {data?.body1 ? (
            <Typography variant="h5" sx={{ color: "white" }}>
              {data?.body1?.title}
            </Typography>
          ) : (
            <></>
          )}
          {data?.body1?.para ? (
            data?.body1.para.map((item) => (
              <Typography sx={{ color: "#8c99ab", padding: "1rem 0" }}>
                {item}
              </Typography>
            ))
          ) : (
            <></>
          )}
        </Box>

        {/* body1 end */}

        {/* body2 */}
        <Box sx={{ padding: "1rem 0" }}>
          {data?.body2 ? (
            <Typography variant="h5" sx={{ color: "white" }}>
              {data?.body2?.title}
            </Typography>
          ) : (
            <></>
          )}
          {data?.body2?.para ? (
            data?.body2.para.map((item) => (
              <Typography sx={{ color: "#8c99ab", padding: "1rem 0" }}>
                {item}
              </Typography>
            ))
          ) : (
            <></>
          )}
        </Box>

        {/* body2 end */}

        {/* body3 */}
        <Box sx={{ padding: "1rem 0" }}>
          {data?.body3 ? (
            <Typography variant="h5" sx={{ color: "white" }}>
              {data?.body3?.title}
            </Typography>
          ) : (
            <></>
          )}
          {data?.body3?.para ? (
            data?.body3.para.map((item) => (
              <Typography sx={{ color: "#8c99ab", padding: "1rem 0" }}>
                {item}
              </Typography>
            ))
          ) : (
            <></>
          )}
        </Box>

        {/* body3 end */}

        {/* body4 */}
        <Box sx={{ padding: "1rem 0" }}>
          {data?.body4 ? (
            <Typography variant="h5" sx={{ color: "white" }}>
              {data?.body4?.title}
            </Typography>
          ) : (
            <></>
          )}
          {data?.body4?.para ? (
            data?.body4.para.map((item) => (
              <Typography sx={{ color: "#8c99ab", padding: "1rem 0" }}>
                {item}
              </Typography>
            ))
          ) : (
            <></>
          )}
        </Box>
        {/* body4 end */}

        {/* body5 */}
        <Box sx={{ padding: "1rem 0" }}>
          {data?.body5 ? (
            <Typography variant="h5" sx={{ color: "white", padding: "1rem 0" }}>
              {data?.body5?.title}
            </Typography>
          ) : (
            <></>
          )}
          {data?.body5?.para ? (
            data?.body5.para.map((item) => (
              <Typography sx={{ color: "#8c99ab", padding: "1rem 0" }}>
                {item}
              </Typography>
            ))
          ) : (
            <></>
          )}
        </Box>
        {/* body5 end */}

        {/* body6 */}
        <Box sx={{ padding: "1rem 0" }}>
          {data?.body6 ? (
            <Typography variant="h5" sx={{ color: "white" }}>
              {data?.body6?.title}
            </Typography>
          ) : (
            <></>
          )}
          {data?.body6?.para ? (
            data?.body6.para.map((item) => (
              <Typography sx={{ color: "#8c99ab", padding: "1rem 0" }}>
                {item}
              </Typography>
            ))
          ) : (
            <></>
          )}
        </Box>

        {/* body6 end */}
      </Box>
    </>
  );
};

export default Top;
