import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";

import { useMediaQuery, useTheme } from "@mui/material";

const PopUp = ({ onClose, children, height, width }) => {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.down("md"));
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "#0c0a09",
        zIndex: 999,
        // border: "1px solid red"
      }}
      onClick={onClose}
    >
      <Box
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          width: (isSmall || isMedium) ? "90vw" : width,
          height: (isSmall || isMedium) ? "30vh" : height,
          // background: "red",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 99999999999,
          transform: "translate(-50%, -50%)",
        }}
      >
        <Box
          sx={{
            background: "#1c2427",
            padding: "1rem",
            borderRadius: "8px",
            width: "100%",
            height: "100%",
            border: "1px solid gray",
            position: "relative",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <CloseIcon
            onClick={onClose}
            sx={{
              position: "absolute",
              fontSize: "2rem",
              padding: "0.3rem",
              right: -15,
              top: -10,
              color: "yellow",
              zIndex: 9999,
              backgroundColor: "#1c2427",
              border: "1px solid gray",
              borderRadius: "50%",
              cursor: "pointer",
            }}
          />
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default PopUp;
